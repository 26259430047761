<template>
  <!-- eslint-disable max-len -->
  <div @click="emitClicked" :id="person.id" class="align-center align-text-center profile">
    <el-card class="d-flex-column align-text-center full-width profile-card">
      <div class="black-background">
        <h4 class="nrl-id">{{ person.id || memberData._id }}</h4>

        <div class="profile-popping" v-if="hasPhoto">
          <vue-upload-component
            v-if="editing"
            ref="upload"
            class="upload-component"
            inputId="headshotUpload"
            :multiple="true"
            :drop="true"
            v-model="uploads"
            :post-action="headshotUploadURL"
            :headers="uploadHeaders"
            :data="memberData"
            @input-file="inputFile"
            @input-filter="inputFilter"
          >
            <!-- UPLOAD ICON -->
            <div class="upload-logo">
              <svg
                width="18px"
                height="16px"
                viewBox="0 0 18 16"
                xmlns="http://www.w3.org/2000/svg"
                xmlns:xlink="http://www.w3.org/1999/xlink"
              >
                <g id="Symbols" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                  <g
                    id="Artboard"
                    transform="translate(-47.000000, -88.000000)"
                    fill="#FFFFFF"
                    fill-rule="nonzero"
                  >
                    <path
                      d="M48.9756098,103.852104 C47.8887171,103.852104 47,102.913299 47,101.765148 L47,92.4897855 C47,91.3416348 47.8887171,90.402829 48.9756098,90.402829 L51.7538,90.402829 L52.5220927,88.3810806 C52.5867013,88.2038545 52.74706,88.0861414 52.9268293,88.0839791 L59.0731707,88.0839791 C59.25294,88.0861414 59.4132987,88.2038545 59.4779073,88.3810806 L60.2462,90.402829 L63.0243902,90.402829 C64.1112829,90.402829 65,91.3416348 65,92.4897855 L65,101.765148 C65,102.913299 64.1112829,103.852104 63.0243902,103.852104 L48.9756098,103.852104 Z M48.9756098,102.924568 L63.0243902,102.924568 C63.6400341,102.924568 64.1219512,102.41549 64.1219512,101.765148 L64.1219512,92.4897855 C64.1219512,91.8394435 63.6400341,91.3303652 63.0243902,91.3303652 L59.9512195,91.3303652 C59.7731474,91.3293876 59.6132696,91.2148899 59.5464829,91.0405101 L58.7781902,89.0115154 L53.2218098,89.0115154 L52.4535171,91.0405101 C52.3867304,91.2148899 52.2268526,91.3293876 52.0487805,91.3303652 L48.9756098,91.3303652 C48.3599659,91.3303652 47.8780488,91.8394435 47.8780488,92.4897855 L47.8780488,101.765148 C47.8780488,102.41549 48.3599659,102.924568 48.9756098,102.924568 Z M56,101.30138 C53.8230317,101.30138 52.0487805,99.4271536 52.0487805,97.1274667 C52.0487805,94.8277565 53.8230317,92.9535536 56,92.9535536 C58.1770122,92.9535536 59.9512195,94.8277797 59.9512195,97.1274667 C59.9512195,99.4271536 58.1770122,101.30138 56,101.30138 Z M56,100.373843 C57.7024707,100.373843 59.0731707,98.9258667 59.0731707,97.1274667 C59.0731707,95.3290435 57.7024707,93.8810899 56,93.8810899 C54.2975732,93.8810899 52.9268293,95.3290435 52.9268293,97.1274667 C52.9268293,98.9258667 54.2975732,100.373843 56,100.373843 Z"
                      id="Shape"
                    ></path>
                  </g>
                </g>
              </svg>
            </div>
          </vue-upload-component>

          <!-- HEADSHOT -->
          <div
            class="logoWithUpload"
            :style="showBg ? { 'background-image': `url(${bg})` } : {}"
          ></div>
        </div>
        <h2 class="m-0">{{ person.firstName }} {{ person.lastName }}</h2>
        <div
          v-if="profileScreen && person.activeRegos && person.activeRegos.length > 0"
          class="player-type"
        >
          <span v-for="(type, index) in activeRegoTypesArray" :key="index">{{ type }}</span>
        </div>
        <div
          v-if="profileScreen && person.activeRegos && person.activeRegos.length > 0"
          class="mt-1"
        ></div>
        <p v-if="age && person.dob">{{ ageRange }}</p>
      </div>
      <div v-if="hasPhoto && editing" style="text-align:left; padding: 20px;font-size: 14px;">
        <i class="el-icon-info" style="font-size: 16px;"></i> Image format must be JPEG or PNG. We
        recommend photos comply with the following:
        <ul>
          <li>True likeness of the participant</li>
          <li>A recent photo taken in the last 6 months</li>
          <li>No hats or sunglasses</li>
          <li>Passport style photo taken from the shoulders up on a clear background</li>
        </ul>
      </div>
      <div class="white-background">
        <div v-if="profileScreen">
          <div v-if="activeNrlEntities.length > 0" class="active-entities d-flex-column mb-1">
            <h4>Rugby League</h4>
            <div class="d-flex">
              <div
                v-for="(entity, index) in activeNrlEntities"
                :key="index"
                class="entity d-flex-column mb-0 mt-0"
              >
                <div>
                  <img
                    :src="endpointParams.logo(entity.entityId)"
                    :alt="`${entity.entityName} Logo`"
                    @error="imgUrlAlt"
                  />
                </div>
                <div>{{ entity.entityName }}</div>
              </div>
            </div>
          </div>

          <div class="active-entities d-flex-column mb-1" v-if="activeTfaEntities.length > 0">
            <h4>Touch Football</h4>
            <div class="d-flex">
              <div
                v-for="(entity, index) in activeTfaEntities"
                :key="index"
                class="entity d-flex-column mb-0 mt-0"
              >
                <div>
                  <!-- TEAM REGISTRATION / ASSOCIATION REGISTRATION -->
                  <img
                    :src="
                      endpointParams.logo(
                        entity.entityOrgtree.association
                          ? entity.entityOrgtree.association._id
                          : entity.entityId
                      )
                    "
                    :alt="`${entity.entityName} Logo`"
                    @error="imgUrlAlt"
                  />
                </div>
                <div>{{ entity.entityName }}</div>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <div v-if="person.meta && person.meta.importedTfa" class="national-org mt-1 d-flex">
            <div class="image" style="padding-left:2px">
              <img src="/images/NRL_TouchFootballv2.svg" alt="TFA Logo" />
            </div>
            <div><h3>Touch Football</h3></div>
          </div>
        </div>
        <div v-if="notEligible" class="no-products padding-sides">
          Please be advised that this member is unavailable for registration at this time. For
          further information please contact the relevant State Body (NSWRL, QRL, NRLSA, NRLVIC,
          NRLWA, NRLNT, NRLTAS).
        </div>
        <div v-else-if="noProducts" class="no-products padding-sides">
          The profile you have selected doesn't fit the registration offerings of this entity.
          Please select a different profile, find another entity or
          <a @click="goToRegisterInterest" href="#">register your interest.</a>
        </div>

        <el-button
          v-if="thisIsMineButton"
          @click="chooseProfile(person)"
          type="success"
          class="full-width button-light button-thin mw-311 mt-1 mb-1 padding-sides"
        >
          This is mine
        </el-button>

        <div v-if="profileScreen" class="d-flex-column padding-sides">
          <div class="mt-1 mb-1">
            <el-button
              @click="emitEditProfile"
              type="success"
              class="full-width button-light button-thin mw-500"
            >
              Edit Profile
            </el-button>
          </div>
        </div>
      </div>
    </el-card>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from "vuex";
import uniqBy from "lodash/uniqBy";
import VueUploadComponent from "vue-upload-component";
import { headshotUploadURL, memberTypes } from "@/utils/constants";
import { endpointParams } from "@/utils/constants/api";
import { calculateAge } from "@/utils/date/minor";

export default {
  name: "Profile",
  components: { VueUploadComponent },
  props: {
    person: Object,
    thisIsMineButton: Boolean,
    chooseProfile: Function,
    mobile: Boolean,
    email: Boolean,
    age: Boolean,
    profileScreen: Boolean,
    noProducts: Boolean,
    notEligible: Boolean
  },
  data() {
    return {
      uploads: [],
      uploadedAvatar: false,
      headshotUploadURL,
      showBg: false,
      endpointParams
    };
  },
  methods: {
    ...mapMutations("root", ["LOADING"]),
    ...mapMutations("views", ["PUSH_NOTIFICATION"]),
    // eslint-disable-next-line no-unused-vars
    inputFile(newFile, oldFile, prevent) {
      if (newFile && newFile.error) {
        this.LOADING(false);
        this.PUSH_NOTIFICATION({
          msg: "Headshot upload has failed",
          type: "warning"
        });
        this.uploads = this.uploads.filter(file => file.id !== newFile.id);
      } else if (newFile && !newFile.active && newFile.response) {
        setTimeout(() => {
          this.uploadedAvatar = newFile.response.resize;
          this.LOADING(false);
        }, 2500);
      }
      return true;
    },
    inputFilter(newFile, oldFile, prevent) {
      if (!/\.(png|jpg|jpeg)$/i.test(newFile.name)) {
        this.PUSH_NOTIFICATION({
          msg: "Please submit a valid file type (png/jpg/jpeg)",
          type: "warning"
        });
        return prevent();
      }
      this.LOADING(true);
      this.$refs.upload.active = true;
      return true;
    },
    emitClicked() {
      this.$emit("profileClicked");
    },
    emitEditProfile() {
      this.$emit("editProfile");
    },
    goToRegisterInterest() {
      this.$router
        .push({
          name: "expressinterest",
          params: { entityType: this.current.entity.entityType, entityId: this.current.entity._id }
        })
        .catch(() => {});
    },
    imgUrlAlt(event) {
      // eslint-disable-next-line no-param-reassign
      event.target.src = "/images/nrl.svg";
    }
  },
  computed: {
    ...mapGetters("user", ["uploadHeaders"]),
    ...mapGetters("registration", ["getCurrentMemberId", "current"]),
    hasPhoto() {
      if (this.$route.name !== "editprofile" && this.$route.name !== "participantdetails") {
        return (
          (this.person && this.person.meta && this.person.meta.avatar) ||
          (this.person && this.person.avatar)
        );
      }
      return true;
    },
    bg() {
      return (
        this.uploadedAvatar ||
        (this.person && this.person.meta && this.person.meta.avatar) ||
        (this.person && this.person.avatar) ||
        "/images/player_placeholder.png"
      );
    },
    editing() {
      return this.$route.name === "editprofile" || this.$route.name === "participantdetails";
    },
    memberData() {
      return {
        _id: this.getCurrentMemberId
      };
    },
    ageRange() {
      const ageByEnd = calculateAge(this.person.dob);
      if (ageByEnd >= 19) return "Seniors";
      return `U${ageByEnd}`;
    },
    activeRegoTypesArray() {
      if (this.person.activeRegos && this.person.activeRegos.length > 0) {
        return this.person.activeRegos.slice(0, 3).map(ar => {
          const foundMemberType = memberTypes.find(mt => mt.type === ar.memberType);
          if (foundMemberType && foundMemberType.type.includes("player")) return "Player";
          if (foundMemberType) return foundMemberType.name;
          return "Other Member";
        });
      }
      return undefined;
    },
    activeNrlEntities() {
      if (this.person.activeRegos && this.person.activeRegos.length > 0) {
        return uniqBy(
          this.person.activeRegos.filter(r => r.national_id === 32).slice(0, 2),
          ar => ar.entityName
        );
      }
      return [];
    },
    activeTfaEntities() {
      if (this.person.activeRegos && this.person.activeRegos.length > 0) {
        return uniqBy(
          this.person.activeRegos.filter(r => r.national_id === 31).slice(0, 2),
          ar => ar.entityName
        );
      }
      return [];
    }
  },
  mounted() {
    setTimeout(() => {
      this.showBg = true;
    }, 100);
  }
};
</script>

<style scoped lang="scss">
.file-uploads {
  width: 100%;
  height: 100%;
}

.profile {
  .profile-card {
    z-index: 5;
    overflow: hidden;
    border-radius: 15px;
    max-width: 550px;
    margin: 1rem auto;
    .nrl-id {
      margin-top: 0;
      margin-bottom: 10px;
      text-align: right;
      color: $rl-highlight;
      line-height: 1.25rem;
      font-family: $fontBold;
    }
  }

  ::v-deep .el-card__body {
    padding: 0 !important;
  }

  .black-background {
    background: linear-gradient(115deg, #181818 52%, #2a2a2a 52%);
    background-size: 100% 600px;
    background-position: 0 0;
    color: white;
    padding: 20px;
  }
  .white-background {
    padding: 0px 5px;
  }

  .profile-popping {
    position: relative;
    z-index: 6;
    align-self: center;
    margin: auto;
    width: 6.875rem;
    height: 6.875rem;
    overflow: hidden;
    border: 5px solid white;
    box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
    border-radius: 50%;
    margin-bottom: 10px;

    .upload-logo {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 75%;
      left: 0;
      right: 0;
      background: black;
      opacity: 0.6;
      z-index: 1;
    }

    .logoWithUpload {
      top: 0;
      right: 0;
      left: 0;
      position: absolute;
      height: 6.875rem;
      background-position: center;
      background-size: cover;
    }
  }

  .no-products {
    border: 1px solid lightgray;
    padding: 0.5rem;
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
    border-radius: 4px;
  }

  .player-type {
    span {
      font-family: $fontMedium;
      font-size: 1.115rem;
      line-height: 1.5rem;
    }
    span + span::before {
      content: " / ";
      color: $rl-highlight;
      font-size: 1.5rem;
      vertical-align: middle;
    }
  }

  .active-entities {
    & + .active-entities {
      border-top: 1px solid lightgray;
    }

    & > div {
      justify-content: center;
    }

    h4 {
      font-family: $fontMedium;
    }

    .entity {
      width: 50%;

      // @media (min-width: $md) {
      //   width: 25%;
      // }

      img {
        height: 50px;
      }
    }
  }

  .padding-sides {
    padding: 0px 15px 15px 15px;
  }

  .national-org {
    justify-content: center;
    .image {
      width: 40px;
      img {
        height: 35px;
      }
    }

    h3 {
      margin: 0px;
    }
  }
}
</style>
